import { createSlice } from '@reduxjs/toolkit';
import {
  createDJAction,
  getDetailDJAction,
  getListMenuBarAction,
  getGenresAction,
  getListThemesAction,
  switchThemesAction,
  getDetailThemeAction,
} from './settings.action';

const initialState: any = {
  isLoading: false,
  error: null,
  listMenuBarSettings: [],
  listThemes: [],
  detailTheme: {},
  listGenres: [],
  DJdetail: {},
  isCreateDJSuccess: false,
  isDeleteDJSuccess: false,
};

const { actions, reducer } = createSlice({
  name: 'settings_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListMenuBarAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListMenuBarAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listMenuBarSettings = action?.payload;
      })
      .addCase(getListMenuBarAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getListThemesAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListThemesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listThemes = action?.payload;
      })
      .addCase(getListThemesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getDetailThemeAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDetailThemeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detailTheme = action?.payload;
      })
      .addCase(getDetailThemeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(switchThemesAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(switchThemesAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(switchThemesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getDetailDJAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDetailDJAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.DJdetail = action?.payload;
      })
      .addCase(getDetailDJAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(createDJAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createDJAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateDJSuccess = true;
      })
      .addCase(createDJAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateDJSuccess = false;
        state.error = action.error;
      })
      .addCase(getGenresAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGenresAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listGenres = action?.payload;
      })
      .addCase(getGenresAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
