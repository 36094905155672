import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const RANKING_API = {
  getListRanking: async params => {
    const response = await new AxiosClient().get('/dj/ranking', { params });
    return response;
  },

  getListRankingAll: async () => {
    const response = await new AxiosClient().get('/dj/ranking', { params: { status: 'all' } });
    return response;
  },

  updateScore: async (id: number | string, params) => {
    const response = await new AxiosClient().put(`/dj/ranking/${id}`, params);
    const data = get(response, 'data', null);
    return data;
  },
};

export default RANKING_API;
