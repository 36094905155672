import { AlignLeftOutlined, DesktopOutlined, MenuOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Layout, Menu, Row, Typography } from 'antd';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import * as yup from 'yup';
import { SelectField } from '@/components/form';
import { LIST_ORGANIZATION, LOCAL_STORAGE_KEY } from '@/constants';
import SpinLoading2 from '@/components/loading/SpinLoading2';
import { useAppSelector } from '@/stores';
import { PrivateLayoutStyle } from './PrivateLayout.style';
import { THEMES_SUB_2_ROUTES, THEMES_SUB_ROUTES, USER_SUB_ROUTES } from '@/routers/_private';

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

const PrivateLayout: React.FC = () => {
  const outlet = useOutlet();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    listUser: { loading: loading1 },
    totalKindUser: { loading: loading2 },
    updateStatus: { loading: loading3 },
    deleteUser: { loading: loading4 },
    userDetail: { loading: loading5 },
  } = useAppSelector(state => state.user);
  const listLoading = [loading1, loading2, loading3, loading4, loading5];
  const isLoadingCommon = useMemo(() => listLoading.some(item => item), [listLoading]);
  const MENU = [
    {
      key: 'category',
      icon: <AlignLeftOutlined />,
      label: <Link to="/admin/category">Category</Link>,
      path: '/admin/category',
    },
    {
      key: '/admin/user',
      icon: <MenuOutlined />,
      label: <>User Management</>,
      children: [
        {
          key: 'user_sub_1',
          icon: <UserOutlined />,
          label: <Link to="/admin/user">User</Link>,
          path: '/admin/user',
        },
      ],
    },
    {
      key: '/admin/settings/themes',
      icon: <SettingOutlined />,
      label: <>Settings</>,
      children: [
        {
          key: 'themes_sub_1',
          icon: <DesktopOutlined />,
          label: <Link to="/admin/settings/themes">Themes</Link>,
          path: '/admin/settings/themes',
        },
        // {
        //   key: 'themes_sub_2',
        //   icon: <AlignLeftOutlined />,
        //   label: <Link to="/admin/settings/preferences">Preferences</Link>,
        //   path: '/admin/settings/preferences',
        // },
      ],
    },
  ];

  const [openKeys, setOpenKeys] = useState<any>([]);
  const selectedKeys = useMemo(() => {
    const pathname = location.pathname;

    const foundItem = MENU.find(item => pathname?.includes(item.key));

    const funcCheckUrl = () => {
      if (foundItem) {
        setOpenKeys([foundItem.key]);
        const funcCallback = item => {
          if (item.path === pathname) {
            return item.key;
          } else if (item.children) {
            for (let i = 0; i < item.children.length; i++) {
              const x = item.children[i];
              const result = funcCallback(x);
              if (result) {
                setOpenKeys(prev => [...prev, x.key]);
                return result;
              }
            }
          }
        };
        return funcCallback(foundItem);
      } else {
        return MENU.find(item => item.path === pathname)?.key;
      }
    };

    let key: any;
    if (!funcCheckUrl()) {
      const pathnameParts = pathname.split('/');
      const newPathname1 = pathnameParts.join('/');
      const newPathname2 = pathnameParts.slice(0, -1).join('/');

      if (USER_SUB_ROUTES.find(item => item === newPathname1 || item === newPathname2)) {
        key = 'user_sub_1';
        setOpenKeys(['/admin/user']);
      } else if (THEMES_SUB_ROUTES.find(item => item === newPathname1 || item === newPathname2)) {
        key = 'themes_sub_1';
        setOpenKeys(['/admin/settings/themes']);
      } else if (THEMES_SUB_2_ROUTES.find(item => item === newPathname1 || item === newPathname2)) {
        key = 'themes_sub_2';
        setOpenKeys(['/admin/settings/themes']);
      }
    } else key = funcCheckUrl();

    return key;
  }, [location.pathname]);

  const validationSchema = yup.object().shape({
    app_id: yup.string().required('app_id REQUIRED'),
  });

  const initialValues = {
    app_id: localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID) ?? '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: () => {},
  });

  return (
    <PrivateLayoutStyle>
      {isLoadingCommon && <SpinLoading2 />}
      <Sider className="layout-sider" breakpoint="lg" collapsedWidth="0" onBreakpoint={_ => {}} onCollapse={_ => {}}>
        <div className="logo">
          {/* <Image preview={false} alt="logo" src={LOGO_IMAGE} className="logo-img" />
           */}
          Matching Platform Admin
        </div>
        <Menu
          className="layout-menu"
          mode="inline"
          defaultOpenKeys={openKeys}
          defaultSelectedKeys={[selectedKeys]}
          items={MENU}
        />
      </Sider>
      <Layout className="layout-body">
        <Header className="body-header">
          <Row>
            <Col span={8} className="body-header-title"></Col>
            <Col span={14} className="select-form-container">
              <Form name="login" className="select-form" onFinish={() => formik.handleSubmit()}>
                <Text>Select app</Text>
                <SelectField
                  field={formik.getFieldProps('app_id')}
                  setFieldValue={(field, value) => {
                    localStorage.setItem(LOCAL_STORAGE_KEY.APP_ID, value);
                    const name = LIST_ORGANIZATION.find(item => item.value === value)?.name ?? '';
                    localStorage.setItem(LOCAL_STORAGE_KEY.APP_NAME, name);
                    const urlChange1 = '/admin/user';
                    if (location?.pathname?.includes(urlChange1)) {
                      window.location.href = urlChange1;
                    } else {
                      window.location.reload();
                    }
                  }}
                  error={formik.errors.app_id}
                  touched={formik.touched.app_id}
                  selectProps={{
                    placeholder: 'Select an application',
                    getPopupContainer: (triggerNode: HTMLElement) => triggerNode?.parentNode as HTMLElement,
                  }}
                  labelAlign="left"
                  optionsSelect={LIST_ORGANIZATION}
                  className="select-style-2"
                />

                {/* <Button className="btn-submit" htmlType="submit" type="primary">
                  Submit
                </Button> */}
              </Form>
            </Col>
            <Col span={2}>
              <Button
                onClick={() => {
                  localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
                  navigate('/admin/login');
                }}
              >
                {t('global.logout')}
              </Button>
            </Col>
          </Row>
        </Header>

        <Content className="body-content">{outlet}</Content>

        <Footer className="body-footer">Matching Platform</Footer>
      </Layout>
    </PrivateLayoutStyle>
  );
};

export default PrivateLayout;
