import { combineReducers } from '@reduxjs/toolkit';

import { appReducer } from '@/stores/app';

import { reducer as authReducer } from './screens/auth/auth.reducer';
import { reducer as djReducer } from './screens/djs/djs.reducer';
import { reducer as userReducer } from './screens/users/users.reducer';
import { reducer as scoreReducer } from './screens/score/score.reducer';
import { reducer as rankingReducer } from './screens/ranking/ranking.reducer';
import { reducer as votingTimeReducer } from './screens/voting-time/voting-time.reducer';
import { reducer as categoryReducer } from './screens/category/category.reducer';
import { reducer as settingsReducer } from './screens/settings/settings.reducer';
import { reducer as homeReducer } from './screens/home/reducer';

export const reducer = combineReducers({
  app: appReducer,
  dj: djReducer,
  auth: authReducer,
  user: userReducer,
  score: scoreReducer,
  ranking: rankingReducer,
  votingTime: votingTimeReducer,
  category: categoryReducer,
  settings: settingsReducer,
  home: homeReducer,
});
