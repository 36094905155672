import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const HOME_API = {
  sendEmail: async params => {
    const response = await new AxiosClient().post('/send-email-contact', params, {
      headers: { 'Content-Type': 'application/json' },
    });
    const data = get(response, 'data', null);
    return data;
  },
};

export default HOME_API;
