import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const USER_API = {
  getListUser: async params => {
    const response = await new AxiosClient().get('/admin/user/list', { params });
    return response;
  },

  getTotalKindUser: async params => {
    const response = await new AxiosClient().get('/admin/user/list/total', { params });
    return response;
  },

  changeStatus: async (id: number | string, data: any) => {
    const response = await new AxiosClient().put(`/admin/user/action/${id}`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    const rs = get(response, 'data', null);
    return rs;
  },

  deleteUser: async (id: number | string) => {
    const response = await new AxiosClient().delete(`/admin/user/${id}`);
    return response;
  },

  detailUser: async (id: number | string, tab: string, params?: any) => {
    const response = await new AxiosClient().get(`/admin/user/detail/${id}/${tab}`, { params });
    const data = get(response, 'data', null);
    return data;
  },

  // ==

  createUser: async params => {
    const response = await new AxiosClient().post('/user', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const data = get(response, 'data', null);
    return data;
  },

  updateUser: async (id: number | string, params) => {
    const response = await new AxiosClient().post(`/user/${id}`, params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const data = get(response, 'data', null);
    return data;
  },

  getUserVotedList: async (id: number | string) => {
    const response = await new AxiosClient().get(`/dj/voted/${id}`);
    const data = get(response, 'data', null);
    return data;
  },
};

export default USER_API;
