import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const AUTH_API = {
  loginAPI: async (params: Auth.LoginRequestData) => {
    const response = await new AxiosClient().post('/login', params);
    const data = get(response, 'data', null);
    return data;
  },
};

export default AUTH_API;
