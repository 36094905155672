import { createAsyncThunk } from '@reduxjs/toolkit';
import AUTH_API from './auth.api';
import { LOCAL_STORAGE_KEY } from '@/constants';

export const loginAction = createAsyncThunk<any, Auth.LoginRequestPayload>(
  'auth',
  async ({ data, callback = () => {} }, { dispatch, rejectWithValue }) => {
    try {
      const user = await AUTH_API.loginAPI(data);

      if (user?.token) {
        localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, user.token);
        callback();
      }

      return user;
    } catch (err: any) {
      rejectWithValue(err?.data || err?.name);
    }
  },
);
