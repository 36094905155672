import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const UPLOAD_API = {
  uploadImage: async params => {
    const response = await new AxiosClient().post('/file/upload-image', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const data = get(response, 'data', null);
    return data;
  },
};

export default UPLOAD_API;
