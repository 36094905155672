import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const SCORE_API = {
  getListScore: async () => {
    const response = await new AxiosClient().get('/score');
    return response;
  },

  updateListScore: async params => {
    const response = await new AxiosClient().put('/score', params, {
      headers: { 'Content-Type': 'application/json' },
    });
    const data = get(response, 'data', null);
    return data;
  },
};

export default SCORE_API;
