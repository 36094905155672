import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const CATEGORY_API = {
  getListCategory: async params => {
    const response = await new AxiosClient().get('/admin/application/category', { params });
    return response;
  },

  detailCategory: async (id: number | string) => {
    const response = await new AxiosClient().get(`/admin/application/category/${id}`);
    const data = get(response, 'data', null);
    return data;
  },

  getCategoryField: async params => {
    const response = await new AxiosClient().get('/admin/application/category/field', { params });
    const data = get(response, 'data', null);
    return data;
  },

  getSubCategory: async params => {
    const response = await new AxiosClient().get('/admin/application/category', { params });
    return response;
  },

  createCategory: async params => {
    const response = await new AxiosClient().post('/admin/application/category', params);
    const data = get(response, 'data', null);
    return data;
  },

  updateCategory: async (id: number | string, params) => {
    const response = await new AxiosClient().put(`/admin/application/category/${id}`, params);
    const data = get(response, 'data', null);
    return data;
  },

  disableCategory: async (id: number | string, params) => {
    const response = await new AxiosClient().put(`/admin/application/category/disabled/${id}`, params);
    const data = get(response, 'data', null);
    return data;
  },

  deleteCategory: async (id: number | string) => {
    const response = await new AxiosClient().delete(`/admin/application/category/${id}`);
    return response;
  },

  getDataSelectCategory: async () => {
    const response = await new AxiosClient().get('/admin/application/category/data-screen');
    const data = get(response, 'data', null);
    return data;
  },

  createCategoryField: async params => {
    const response = await new AxiosClient().post('/admin/application/category/field', params);
    const data = get(response, 'data', null);
    return data;
  },

  updateCategoryField: async (id: number | string, params) => {
    const response = await new AxiosClient().put(`/admin/application/category/field/${id}`, params);
    const data = get(response, 'data', null);
    return data;
  },

  deleteCategoryField: async (id: number | string) => {
    const response = await new AxiosClient().delete(`/admin/application/category/field/${id}`);
    return response;
  },

  createDJ: async params => {
    const response = await new AxiosClient().post('/admin/application/category', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const data = get(response, 'data', null);
    return data;
  },

  deleteDJ: async (id: number | string) => {
    const response = await new AxiosClient().delete(`/admin/application/category/${id}`);
    return response;
  },

  detailDJ: async (id: number | string) => {
    const response = await new AxiosClient().get(`/admin/application/category/${id}`);
    const data = get(response, 'data', null);
    return data;
  },

  updateDJ: async (id: number | string, params) => {
    const response = await new AxiosClient().post(`/admin/application/category/${id}`, params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const data = get(response, 'data', null);
    return data;
  },

  getGenres: async () => {
    const response = await new AxiosClient().get('/genre');
    const data = get(response, 'data', null);
    return data;
  },
};

export default CATEGORY_API;
