import React from 'react';

const DownArrowIcon: React.FC<{ className?: string; onClick?: any; props?: any }> = ({
  className = '',
  onClick,
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#2475B4"
        d="M11.735 14.921a.357.357 0 00.53 0l4.618-4.63c.156-.126.156-.378 0-.535l-.624-.599c-.125-.157-.374-.157-.53 0l-3.713 3.717L8.27 9.157a.357.357 0 00-.53 0l-.624.599c-.156.157-.156.409 0 .535l4.618 4.63z"
      ></path>
    </svg>
  );
};

export default DownArrowIcon;
