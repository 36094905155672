import { ICONS } from '@/assets';
import Image from '@/components/Image';
import { I18n } from '@/configs/i18n/i18n';
import { LOCAL_STORAGE_KEY } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/stores';
import { changeLanguage } from '@/stores/screens/home/reducer';
import { LocalStore } from '@/utils';
import { Select, SelectProps } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
const { EnglandIcon, JapanIcon, KoreaIcon, VietNamIcon } = ICONS;
const { DownArrowIcon } = ICONS;
const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;
const SelectStyled = styled(Select)<SelectProps>`
  min-width: 120px;

  .ant-image {
    width: 24px;
    height: 24px;
    line-height: 24px;

    img {
      border-radius: 50%;
    }
  }
  .ant-select-selection-item {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding-right: 0 !important;
  }
  .ant-select-selector {
    padding: 0 0 !important;
  }

  .ant-select-item-option-content label {
    color: #2475b4;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .label {
    color: #2475b4;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
`;
const SelectLanguage: React.FC = () => {
  const navigate = useNavigate();
  const LIST_LANG = [
    { value: 'en', label: 'ENG', flag: EnglandIcon },
    { value: 'vi', label: 'VI', flag: VietNamIcon },
    { value: 'ja', label: 'JA', flag: JapanIcon },
    { value: 'kr', label: 'KR', flag: KoreaIcon },
  ];
  const {
    language: { isLoading },
  } = useAppSelector(state => state.home);
  const dispatch = useAppDispatch();

  const handleChangeLanguage = (value: string): any => {
    LocalStore.set(LOCAL_STORAGE_KEY.LANGUAGE, value);
    dispatch(changeLanguage());
    navigate(0);
    void I18n.changeLanguage(value);
  };

  return (
    <>
      <WrapperStyled>
        <SelectStyled
          bordered={false}
          onChange={(e: any) => handleChangeLanguage(e)}
          suffixIcon={<DownArrowIcon />}
          defaultValue={LocalStore.get(LOCAL_STORAGE_KEY.LANGUAGE) || 'en'}
        >
          {!isLoading &&
            LIST_LANG.map(lang => (
              <option value={lang.value} key={lang.value} selected={I18n.language === lang.value}>
                <Image src={lang.flag} style={{ borderRadius: '50%', height: 24, width: 24, objectFit: 'cover' }} />
                <span className="label">{lang.label}</span>
              </option>
            ))}
        </SelectStyled>
      </WrapperStyled>
    </>
  );
};

export default SelectLanguage;
