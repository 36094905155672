import React, { ReactNode } from 'react';
import { Form, Input } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import { FieldInputProps, FormikTouched, FormikErrors } from 'formik';

const { TextArea } = Input;

type Props = {
  label?: string | ReactNode;
  field: FieldInputProps<any>;
  textAreaProps?: TextAreaProps;
  placeholder?: string;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  disabled?: boolean;
  setFieldValue: (field: string, value: any) => any;
  callbackOnChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  pattern?: RegExp;
} & Omit<FormItemProps, 'children'>;

const TextAreaField: React.FC<Props> = ({
  label,
  touched,
  error,
  field,
  textAreaProps,
  placeholder,
  disabled,
  pattern,
  setFieldValue,
  callbackOnChange,
  ...rest
}) => {
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (pattern) {
      if (e.target.value === '' || pattern.test(e.target.value)) {
        setFieldValue(field.name, e.target.value);
      }
    } else {
      setFieldValue(field.name, e.target.value);
    }

    callbackOnChange?.(e);
  };
  return (
    <Form.Item
      colon={false}
      label={label}
      validateStatus={error && touched ? 'error' : ''}
      help={(touched && error) as string}
      {...rest}
    >
      <TextArea
        {...field}
        {...textAreaProps}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        style={{ fontSize: 'inherit' }}
      />
    </Form.Item>
  );
};

export default TextAreaField;
