import { sendEmailAction } from '@/stores/screens/home/actions';
import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
  isLoading: boolean;
  error: any;
  dataReturn: any;
  language: {
    isLoading: boolean;
  };
}

const initialState: initialStateProps = {
  isLoading: false,
  error: null,
  dataReturn: null,
  language: {
    isLoading: false,
  },
};

const { actions, reducer } = createSlice({
  name: 'home_slice',
  initialState,
  reducers: {
    changeLanguage(state) {
      state.language.isLoading = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sendEmailAction.pending, state => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(sendEmailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dataReturn = action.payload;
        state.error = null;
      })
      .addCase(sendEmailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export const { changeLanguage } = actions;
