import { createSlice } from '@reduxjs/toolkit';
import { createDJAction, getDetailDJAction, getListDJAction, getGenresAction } from './djs.action';

const initialState: any = {
  isLoading: false,
  error: null,
  listDJ: [],
  listGenres: [],
  DJdetail: {},
  isCreateDJSuccess: false,
  isDeleteDJSuccess: false,
};

const { actions, reducer } = createSlice({
  name: 'dj_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListDJAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListDJAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listDJ = action?.payload;
      })
      .addCase(getListDJAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getDetailDJAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDetailDJAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.DJdetail = action?.payload;
      })
      .addCase(getDetailDJAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(createDJAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createDJAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateDJSuccess = true;
      })
      .addCase(createDJAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateDJSuccess = false;
        state.error = action.error;
      })
      .addCase(getGenresAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGenresAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listGenres = action?.payload;
      })
      .addCase(getGenresAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
