import HOME_API from '@/stores/screens/home/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const sendEmailAction = createAsyncThunk<any, any>('create_user', async (data, { rejectWithValue }) => {
  try {
    const response = await HOME_API.sendEmail(data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});
