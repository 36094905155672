import React from 'react';
import type { RouteObject } from 'react-router-dom';

import { PrivateLayout } from '@/layouts';

const SelectAppScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.SelectAppScreen })),
);

const CategoryScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.CategoryScreen })),
);

const DetailCategoryScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.DetailCategoryScreen })),
);

const CreateCategoryScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.CreateCategoryScreen })),
);

const EditCategoryScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.EditCategoryScreen })),
);

// const SettingsPreferencesScreen = React.lazy(
//   async () => await import('@/screens/privateScreens').then(module => ({ default: module.SettingsPreferencesScreen })),
// );

const SettingsThemesScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.SettingsThemesScreen })),
);

const DetailThemeScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.DetailThemeScreen })),
);

const EditThemeScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.EditThemeScreen })),
);

const CreateThemeScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.CreateThemeScreen })),
);

const NotFoundScreen = React.lazy(
  async () => await import('@/screens/NotFound').then(module => ({ default: module.NotFound })),
);

// == USER ==
const UsersScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.UsersScreen })),
);

const DetailUserScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.DetailUserScreen })),
);

const CreateUserScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.CreateUserScreen })),
);

const EditUserScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.EditUserScreen })),
);

export const USER_SUB_ROUTES = ['/admin/user/detail', '/admin/user/create', '/admin/user/edit'];

export const THEMES_SUB_ROUTES = [
  '/admin/settings/themes/detail/:id',
  '/admin/settings/themes/edit/:id',
  '/admin/settings/themes/create',
];

export const THEMES_SUB_2_ROUTES = ['/admin/settings/preferences'];

const _privateRoutes: RouteObject[] = [
  {
    element: <PrivateLayout />,
    children: [
      { path: '/admin/category', element: <CategoryScreen /> },
      { path: '/admin/category/detail/:id', element: <DetailCategoryScreen /> },
      { path: '/admin/category/create', element: <CreateCategoryScreen /> },
      { path: '/admin/category/edit/:id', element: <EditCategoryScreen /> },

      // { path: '/admin/settings/preferences', element: <SettingsPreferencesScreen /> },
      { path: '/admin/settings/themes', element: <SettingsThemesScreen /> },
      { path: '/admin/settings/themes/detail/:id', element: <DetailThemeScreen /> },
      { path: '/admin/settings/themes/edit/:id', element: <EditThemeScreen /> },
      { path: '/admin/settings/themes/create', element: <CreateThemeScreen /> },

      { path: '/admin/select-app', element: <SelectAppScreen /> },

      // == USER ==
      { path: '/admin/user', element: <UsersScreen /> },
      { path: '/admin/user/detail/:id', element: <DetailUserScreen /> },
      { path: '/admin/user/create', element: <CreateUserScreen /> },
      { path: '/admin/user/edit/:id', element: <EditUserScreen /> },

      { element: <NotFoundScreen />, path: '*' },
    ],
  },
];

export default _privateRoutes;
