import { Layout } from 'antd';
import styled from 'styled-components';

export const PrivateLayoutStyle = styled(Layout)`
  height: 100%;
  width: 100%;

  .layout-sider {
    min-width: 220px !important;
    max-width: 220px !important;
    &.ant-layout-sider {
      background: #ffffff;
    }

    .logo {
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo-img {
        width: 90px;
        height: 50px;
      }
    }

    .layout-menu {
      .ant-menu-item {
        margin: 0;
      }
    }
  }

  .layout-body {
    .body-header {
      padding: 0;
      background-color: #ffffff;
      text-align: right;
      padding: 0 30px;

      .body-header-title {
        text-align: center;
      }

      .select-form-container {
        display: flex;

        .select-form {
          display: flex;
          width: 100%;
          gap: 4px;
          justify-content: center;
          align-items: center;

          .ant-form-item {
            width: 60%;
            margin: 0;
          }
        }
      }
    }
    .body-content {
      margin: 20px;
      background-color: #ffffff;
      padding: 20px;
      border-radius: 10px;
      overflow-y: auto;
    }
    .body-footer {
      text-align: center;
      padding: 5px 50px;
    }
  }
`;
