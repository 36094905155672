import { createAsyncThunk } from '@reduxjs/toolkit';
import UPLOAD_API from './upload.api';
interface UploadImageData {
  dataSubmit: any;
  callback?: any;
  callbackFailed?: any;
}

export const uploadImageAction = createAsyncThunk<any, UploadImageData>(
  'update_dj',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UPLOAD_API.uploadImage(data?.dataSubmit);
      if (response) {
        data.callback(response);
      }
      return response;
    } catch (err: any) {
      data?.callbackFailed(err);
      return rejectWithValue(err);
    }
  },
);
