/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { createSlice } from '@reduxjs/toolkit';
import {
  createUserAction,
  deleteUserAction,
  getDetailUserAction,
  getListUserAction,
  getTotalKindUserAction,
  getUserVotedListAction,
  updateStatusUserAction,
} from './users.action';

interface initialStateProps {
  isLoading: boolean;
  error: any;
  listUser: {
    data: any[];
    meta: any;
    loading: boolean;
    dataSortBy: any;
  };
  totalKindUser: {
    loading: boolean;
    data: any[];
  };
  listUserVoted: any[];
  userDetail: {
    loading: boolean;
    account: {
      data: any;
      loading: boolean;
    };
    network: {
      data: any[];
      loading: boolean;
      meta: any;
      connection: {
        data: {
          totalMatches: number;
          matchesToday: number;
          interestToday: number;
          endorsementToday: number;
          viewsToday: number;
        };
        loading: boolean;
        meta: any;
      };
    };
    post: {
      data: any[] | any;
      loading: boolean;
      meta: any;
      dataSortBy: any;
    };
    media: {
      data: any[] | any;
      loading: boolean;
      meta: any;
    };
    endorsement: {
      meta: any;
      data: any;
      loading: boolean;
      dataSortBy: any;
    };
  };
  isCreateUserSuccess: boolean;
  isDeleteUserSuccess: boolean;
  updateStatus: {
    loading: boolean;
    data: any;
  };
  deleteUser: {
    loading: boolean;
    data: any;
  };
}

const DATA_SORT_BY = [
  { label: 'All', value: '' },
  {
    label: 'Name A->Z',
    value: 'name|asc',
  },
  {
    label: 'Name Z->A',
    value: 'name|desc',
  },
  {
    label: 'Recent sign in',
    value: 'last_sign_in|desc',
  },
  {
    label: 'Oldest sign in',
    value: 'last_sign_in|asc',
  },
  {
    label: 'Last created',
    value: 'created_at|desc',
  },
  {
    label: 'Oldest created',
    value: 'created_at|asc',
  },
  {
    label: 'Updated date',
    value: 'updated_date|desc',
  },
  {
    label: 'Oldest updated',
    value: 'oldest_updated|asc',
  },
  {
    label: 'Recent last activity',
    value: 'last_activity|desc',
  },
  {
    label: 'Oldest last activity',
    value: 'last_activity|asc',
  },
];

const DATA_SORT_BY_2 = [
  { label: 'All', value: '' },
  { label: 'Last created', value: 'desc' },
  { label: 'Oldest created', value: 'asc' },
];

const initialState: initialStateProps = {
  isLoading: false,
  error: null,
  listUser: {
    data: [],
    meta: undefined,
    loading: false,
    dataSortBy: DATA_SORT_BY,
  },
  totalKindUser: {
    data: [
      { name: 'Active', value: 1, members: 0 },
      { name: 'Deactive', value: 2, members: 0 },
      { name: 'Blocked', value: 3, members: 0 },
    ],
    loading: false,
  },
  listUserVoted: [],
  userDetail: {
    loading: false,
    account: {
      data: undefined,
      loading: false,
    },
    network: {
      connection: {
        data: { totalMatches: 123456, matchesToday: 10, interestToday: 0, endorsementToday: 100, viewsToday: 1000 },
        loading: false,
        meta: undefined,
      },
      data: [],
      loading: false,
      meta: undefined,
    },
    post: {
      meta: undefined,
      data: [],
      loading: false,
      dataSortBy: DATA_SORT_BY_2,
    },
    media: {
      meta: undefined,
      data: [],
      loading: false,
    },
    endorsement: {
      meta: undefined,
      data: [],
      loading: false,
      dataSortBy: DATA_SORT_BY_2,
    },
  },
  isCreateUserSuccess: false,
  isDeleteUserSuccess: false,
  updateStatus: {
    loading: false,
    data: undefined,
  },
  deleteUser: {
    loading: false,
    data: undefined,
  },
};

const { actions, reducer } = createSlice({
  name: 'users_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // list user
      .addCase(getListUserAction.pending, (state, action) => {
        state.listUser.loading = true;
      })
      .addCase(getListUserAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.listUser = {
          ...state.listUser,
          data: data.item,
          meta: data.totalItem,
          loading: false,
        };
      })
      .addCase(getListUserAction.rejected, (state, action) => {
        state.listUser.loading = false;
        state.error = action.error;
      })

      // total kind user
      .addCase(getTotalKindUserAction.pending, (state, action) => {
        state.totalKindUser.loading = true;
      })
      .addCase(getTotalKindUserAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.totalKindUser.loading = false;
        state.totalKindUser.data = [
          { name: 'Active', value: 1, members: data.active },
          { name: 'Deactive', value: 2, members: data.deactived },
          { name: 'Blocked', value: 3, members: data.blocked },
        ];
      })
      .addCase(getTotalKindUserAction.rejected, (state, action) => {
        state.totalKindUser.loading = false;
        state.error = action.error;
      })

      // update status user
      .addCase(updateStatusUserAction.pending, state => {
        state.updateStatus.loading = true;
      })
      .addCase(updateStatusUserAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.updateStatus.loading = false;
        state.updateStatus.data = data;
      })
      .addCase(updateStatusUserAction.rejected, (state, action) => {
        state.updateStatus.loading = false;
        state.error = action.error;
      })

      // delete status user
      .addCase(deleteUserAction.pending, state => {
        state.deleteUser.loading = true;
      })
      .addCase(deleteUserAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.deleteUser.loading = false;
        state.deleteUser.data = data;
      })
      .addCase(deleteUserAction.rejected, (state, action) => {
        state.deleteUser.loading = false;
        state.error = action.error;
      })

      // detail user
      .addCase(getDetailUserAction.pending, (state, action) => {
        state.userDetail.loading = true;
      })
      .addCase(getDetailUserAction.fulfilled, (state, action) => {
        state.userDetail.loading = false;
        // console.log(action.payload);

        if (action.payload.tab === 'profile') {
          state.userDetail.account.data = action.payload;
        } else if (action.payload.tab === 'network') {
          state.userDetail.network.data = action.payload;
        } else if (action.payload.tab === 'post') {
          state.userDetail.post.data = action.payload;
        } else if (action.payload.tab === 'media') {
          state.userDetail.media.data = action.payload;
        } else if (action.payload.tab === 'endorsement') {
          state.userDetail.endorsement.data = action.payload;
        }
      })
      .addCase(getDetailUserAction.rejected, (state, action) => {
        state.userDetail.loading = false;
        state.error = action.error;
      })

      // create user
      .addCase(createUserAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateUserSuccess = true;
      })
      .addCase(createUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateUserSuccess = false;
        state.error = action.error;
      })
      // voted user (no use)
      .addCase(getUserVotedListAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserVotedListAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listUserVoted = action?.payload;
      })
      .addCase(getUserVotedListAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
