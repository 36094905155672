import { createSlice } from '@reduxjs/toolkit';
import {
  getListCategoryAction,
  getDataSelectCategoryAction,
  getGenresAction,
  getDetailCategoryAction,
  getCategoryFieldAction,
  getSubCategoryAction,
} from './category.action';

const initialState: any = {
  isLoading: false,
  error: null,
  listCategory: [],
  detailCategory: {},
  listCategoryField: [],
  listSubCategory: [],
  listGenres: [],
  DJdetail: {},
  dataSelectCategory: {},
  isCreateDJSuccess: false,
  isDeleteDJSuccess: false,
};

const { actions, reducer } = createSlice({
  name: 'category_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListCategoryAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListCategoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listCategory = action?.payload;
      })
      .addCase(getListCategoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getDetailCategoryAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDetailCategoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detailCategory = action?.payload;
      })
      .addCase(getDetailCategoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getDataSelectCategoryAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDataSelectCategoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dataSelectCategory = action?.payload;
      })
      .addCase(getDataSelectCategoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getCategoryFieldAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCategoryFieldAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listCategoryField = action?.payload;
      })
      .addCase(getCategoryFieldAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getSubCategoryAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSubCategoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listSubCategory = action?.payload;
      })
      .addCase(getSubCategoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(getGenresAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGenresAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listGenres = action?.payload;
      })
      .addCase(getGenresAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
