import { createSlice } from '@reduxjs/toolkit';
import { uploadImageAction } from './upload.action';

const initialState: any = {
  isLoading: false,
  error: null,
  linkImage: '',
};

const { actions, reducer } = createSlice({
  name: 'upload_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(uploadImageAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(uploadImageAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.linkImage = action?.payload;
      })
      .addCase(uploadImageAction.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action?.payload?.code ?? action?.payload?.message;
      });
  },
});

export { reducer };
export default actions;
