import { createSlice } from '@reduxjs/toolkit';
import { updateTimeVotingAction, getTimeVotingAction } from './voting-time.action';

const initialState: any = {
  isLoading: false,
  error: null,
  timeVoting: {},
};

const { actions, reducer } = createSlice({
  name: 'score_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTimeVotingAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTimeVotingAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timeVoting = action?.payload?.data;
      })
      .addCase(getTimeVotingAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(updateTimeVotingAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateTimeVotingAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateTimeVotingAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
