import { createSlice } from '@reduxjs/toolkit';
import { getListRankingAction, getListRankingAllAction, updateScoreAction } from './ranking.action';

const initialState: any = {
  isLoading: false,
  error: null,
  listRanking: [],
  listRankingAll: [],
};

const { actions, reducer } = createSlice({
  name: 'ranking_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListRankingAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListRankingAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listRanking = action?.payload?.data;
      })
      .addCase(getListRankingAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getListRankingAllAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListRankingAllAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listRankingAll = action?.payload?.data;
      })
      .addCase(getListRankingAllAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      .addCase(updateScoreAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateScoreAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateScoreAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
