import React from 'react';
import styled from 'styled-components';
import { Image as AntdImage, ImageProps } from 'antd';
import { IMAGES } from '@/assets';
interface CProps extends ImageProps {
  src: any;
  props?: any;
}
const ImageStyled = styled(AntdImage)`
  .ant-image-img {
    height: 100%;
  }
`;

const Image: React.FC<CProps> = ({ src, alt, className, ...props }) => {
  return (
    <ImageStyled
      src={src ?? IMAGES.DefaultImage}
      alt={alt}
      className={className}
      preview={false}
      onError={(e: any) => {
        e.target.src = IMAGES.DefaultImage;
      }}
      {...props}
    />
  );
};

Image.defaultProps = {
  alt: '',
  className: '',
  onClick: () => {},
  onMouseDown: () => {},
  onMouseUp: () => {},
};

export default Image;
