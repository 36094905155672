import { createAsyncThunk } from '@reduxjs/toolkit';

import SCORE_API from './score.api';

export const getListScoreAction = createAsyncThunk<any>('score', async (_, { dispatch, rejectWithValue }) => {
  try {
    const res = await SCORE_API.getListScore();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const updateListScoreAction = createAsyncThunk<any, any>('update-score', async (data, { rejectWithValue }) => {
  try {
    const response = await SCORE_API.updateListScore(data);
    window.location.reload();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});
