import { createAsyncThunk } from '@reduxjs/toolkit';

import RANKING_API from './ranking.api';

export const getListRankingAction = createAsyncThunk<any, any>(
  'ranking',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const res = await RANKING_API.getListRanking(params);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getListRankingAllAction = createAsyncThunk<any>(
  'ranking-all',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await RANKING_API.getListRankingAll();
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateScoreAction = createAsyncThunk<any, any>('update_score', async (data, { rejectWithValue }) => {
  try {
    const response = await RANKING_API.updateScore(data?.id, data?.dataSubmit);
    data?.callback();
    return response;
  } catch (err: any) {
    data?.callbackFail(err);
    return rejectWithValue(err?.response?.data || err?.name);
  }
});
