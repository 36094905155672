import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const SETTINGS_API = {
  getListMenuBarConfig: async params => {
    const response = await new AxiosClient().get('/admin/application/config', { params });
    return response;
  },

  getListThemes: async params => {
    const response = await new AxiosClient().get('/admin/themes', { params });
    return response;
  },

  detailTheme: async (id: number | string) => {
    const response = await new AxiosClient().get(`/admin/themes/${id}`);
    const data = get(response, 'data', null);
    return data;
  },

  updateTheme: async (id: number | string, params) => {
    const response = await new AxiosClient().put(`/admin/themes/${id}`, params);
    const data = get(response, 'data', null);
    return data;
  },

  deleteTheme: async (id: number | string) => {
    const response = await new AxiosClient().delete(`/admin/themes/${id}`);
    const data = get(response, 'data', null);
    return data;
  },

  createTheme: async params => {
    const response = await new AxiosClient().post('/admin/themes', params);
    const data = get(response, 'data', null);
    return data;
  },

  switchTheme: async params => {
    const response = await new AxiosClient().post('/admin/themes/active', params);
    const data = get(response, 'data', null);
    return data;
  },

  createDJ: async params => {
    const response = await new AxiosClient().post('/dj', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const data = get(response, 'data', null);
    return data;
  },

  deleteDJ: async (id: number | string) => {
    const response = await new AxiosClient().delete(`/dj/${id}`);
    return response;
  },

  detailDJ: async (id: number | string) => {
    const response = await new AxiosClient().get(`/dj/${id}`);
    const data = get(response, 'data', null);
    return data;
  },

  updateDJ: async (id: number | string, params) => {
    const response = await new AxiosClient().post(`/dj/${id}`, params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const data = get(response, 'data', null);
    return data;
  },

  getGenres: async () => {
    const response = await new AxiosClient().get('/genre');
    const data = get(response, 'data', null);
    return data;
  },
};

export default SETTINGS_API;
