import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const TIME_VOTING_API = {
  getTimeVoting: async () => {
    const response = await new AxiosClient().get('/voting-time');
    return response;
  },

  updateTimeVoting: async params => {
    const response = await new AxiosClient().put('/voting-time', params, {
      headers: { 'Content-Type': 'application/json' },
    });
    const data = get(response, 'data', null);
    return data;
  },
};

export default TIME_VOTING_API;
