import AppStore from '@/assets/images/app-store.svg';
import BottomLeftImage from '@/assets/images/bottom-left-image.svg';
import CheckIcon from '@/assets/images/check-icon.svg';
import DefaultLogo from '@/assets/images/default-logo.svg';
import DownArrow from '@/assets/images/down-arrow.svg';
import FloatRightImage from '@/assets/images/float-right-image.svg';
import GooglePlay from '@/assets/images/google-play.svg';
import LeftMenuImage from '@/assets/images/left-menu-image.svg';
import TopRightImage from '@/assets/images/top-right-image.svg';
import DefaultImage from '@/assets/images/default.jpg';

//= ======== WHY
import BackgroundWhy from '@/assets/images/why/bg.jpg';
import Why5 from '@/assets/images/why/why-5.png';

//= ======== HOW TO
import HowTo6 from '@/assets/images/howTo/how-to-6.png';
import HowTo7 from '@/assets/images/howTo/how-to-7.png';

//= ======== POLICY
import Policy2 from '@/assets/images/policy/center.png';
import Policy4 from '@/assets/images/policy/bg.jpg';

//= ======== ICON ===========

import DownArrowIcon from '@/assets/icons/DownArrowIcon';
import FacebookIcon from '@/assets/icons/facebook-icon.svg';
import LinkedInIcon from '@/assets/icons/linkedin-icon.svg';
import MessengerIcon from '@/assets/icons/messenger-icon.svg';
import ToTopIcon from '@/assets/icons/to-top-icon.svg';
import YoutubeIcon from '@/assets/icons/youtube-icon.svg';
import VietNamIcon from '@/assets/icons/vietnam-flag-icon.svg';
import JapanIcon from '@/assets/icons/japan-flag-icon.svg';
import KoreaIcon from '@/assets/icons/south-korea-flag-icon.svg';
import EnglandIcon from '@/assets/icons/united-kingdom-flag-icon.svg';
import IOSIcon from '@/assets/icons/ios-icon.svg';
import AndroidIcon from '@/assets/icons/android-icon.svg';

const IMAGES = {
  LeftMenuImage,
  FloatRightImage,
  CheckIcon,
  AppStore,
  BottomLeftImage,
  DefaultLogo,
  DownArrow,
  GooglePlay,
  TopRightImage,
  Why5,
  BackgroundWhy,
  HowTo6,
  HowTo7,
  Policy2,
  Policy4,
  DefaultImage,
};

const ICONS = {
  FacebookIcon,
  LinkedInIcon,
  MessengerIcon,
  ToTopIcon,
  DownArrowIcon,
  YoutubeIcon,
  VietNamIcon,
  JapanIcon,
  KoreaIcon,
  EnglandIcon,
  IOSIcon,
  AndroidIcon,
};

export { ICONS, IMAGES };
