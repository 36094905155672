import { createSlice } from '@reduxjs/toolkit';
import { updateListScoreAction, getListScoreAction } from './score.action';

const initialState: any = {
  isLoading: false,
  error: null,
  listScore: [],
};

const { actions, reducer } = createSlice({
  name: 'score_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListScoreAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListScoreAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listScore = action?.payload?.data;
      })
      .addCase(getListScoreAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(updateListScoreAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateListScoreAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateListScoreAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
