/* eslint-disable no-useless-escape */
export const NUMBER_REGEX = /^[0-9\b]+$/;

export const URL_YOUTUBE_VIDEO_REGEX = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]+(&\S*)?\/?$/;
export const URL_YOUTUBE_CHANNEL_REGEX = /^(https?:\/\/)?(www\.)?youtube\.com\/.*\/?$/;

export const URL_FACEBOOK_REGEX = /^(https?:\/\/)?(www\.)?facebook\.com\/(profile\.php\?id=\d+|[\w.]+)\/?$/;

export const URL_TWITTER_REGEX = /^(https?:\/\/)?(www\.)?twitter\.com\/[A-Za-z0-9_]{1,15}\/?$/;

export const URL_INSTAGRAM_REGEX = /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9_\.]{1,30}\/?$/;

export const URL_SOUNDCLOUD_REGEX = /^(https?:\/\/)?(www\.)?soundcloud\.com\/[A-Za-z0-9_\-]+\/?$/;

export const URL_WEBSITE_REGEX = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?\/?$/;
