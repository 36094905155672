import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANGUAGE, LOCAL_STORAGE_KEY } from '@/constants';

import { en } from './locales/en';
import { vi } from './locales/vi';
import { ja } from './locales/ja';
import { kr } from './locales/kr';
import { LocalStore } from '@/utils';

const selectedLanguage = LocalStore.get(LOCAL_STORAGE_KEY.LANGUAGE) || 'en';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: LANGUAGE.VI,
    debug: false,
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    resources: {
      en: {
        translation: en,
      },
      vi: {
        translation: vi,
      },
      kr: {
        translation: kr,
      },
      ja: {
        translation: ja,
      },
    },
    lng: selectedLanguage,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  .catch(error => {
    throw error;
  });

const I18n = i18n;
// const t = (...args: Parameters<TFunction>) => I18n.t(...args);

I18n.on('languageChanged', (language) => {
  LocalStore.set(LOCAL_STORAGE_KEY.LANGUAGE, language);
});
export { I18n };
