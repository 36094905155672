import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const DJ_API = {
  getListDJ: async params => {
    const response = await new AxiosClient().get('/dj', { params });
    return response;
  },

  createDJ: async params => {
    const response = await new AxiosClient().post('/dj', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const data = get(response, 'data', null);
    return data;
  },

  deleteDJ: async (id: number | string) => {
    const response = await new AxiosClient().delete(`/dj/${id}`);
    return response;
  },

  detailDJ: async (id: number | string) => {
    const response = await new AxiosClient().get(`/dj/${id}`);
    const data = get(response, 'data', null);
    return data;
  },

  updateDJ: async (id: number | string, params) => {
    const response = await new AxiosClient().post(`/dj/${id}`, params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const data = get(response, 'data', null);
    return data;
  },

  getGenres: async () => {
    const response = await new AxiosClient().get('/genre');
    const data = get(response, 'data', null);
    return data;
  },
};

export default DJ_API;
