export const LIST_ORGANIZATION = [
  { value: 'f384229d-7a2e-400a-831a-de4b25d8ae0f', label: 'Professional', name: 'professional' },
  { value: '055c727f-0a81-44f0-8e26-c450fbd0ac1d', label: 'Renting', name: 'renting' },
  { value: 'b34ae952-1da4-48af-abde-174649d53ab5', label: 'Dating', name: 'dating' },
  { value: '5dc43935-0628-4f4a-8775-a5a60022d1a6', label: 'Recruiting', name: 'recruiting' },
  { value: '63f41058-6166-4a32-90e8-abab5cb93970', label: 'Investment', name: 'investment' },
];

export const LIST_META_TITLES = [
  'title',
  'og:title',
  'meta[name="og:title"]',
  'meta[property="og:title"]',
  'meta[name="twitter:title"]',
  'meta[property="twitter:title"]',
  'og:site_name',
  'meta[property="og:site_name"]',
  'meta[name="og:site_name"]',
];
export const LIST_META_DESCRIPTIONS = [
  'meta[name="description"]',
  'meta[name="og:description"]',
  'meta[name="twitter:description"]',
  'meta[property="description"]',
  'meta[property="og:description"]',
  'meta[property="twitter:description"]',
  'twitter:description',
];
export const LIST_META_IMAGES = [
  'meta[property="og:image"]',
  'meta[property="twitter:image"]',
  'meta[name="og:image"]',
  'meta[name="twitter:image"]',
];
